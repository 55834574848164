const HEADER1 = {
  id: {},
  name: {},
  status: {},
  role: {},
  phone: {},
  location: {},
  lastNotification: {},
  activity: {},
};
const HEADER2 = {
  id: {},
  name: {},
  status: {},
  aiModels: {},
  type: {},
  location: {},
  url: {},
  settings: {},
};
const HEADER3 = {
  id: {},
  name: {},
  status: {},
  location: {},
  userNotification: {},
  userAttend: {},
  date: {},
  settings: {},
};

const BODY1 = [
  ['123123123', 'Miguel López', 'Active', 'User', '654123123', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Paco Pérez', 'Active', 'SuperAdmin', '654123123', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Sara Peña', 'Active', 'User', '654123123', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Virginia García', 'Active', 'Admin', '654123123', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Manuel Sánchez', 'Inactive', 'User', '654123123', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Fernando Sosa', 'Inactive', 'Admin', '654123123', 'Secmotic 18', '26/3/24 18:30'],
];
const BODY2 = [
  ['123123123', 'Cam 1', 'Active', 'AI_1,AI_2', 'Mobile Cam', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 2', 'Active', 'AI_3', 'Mobile Cam', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 3', 'Active', 'AI_3', 'Mobile Cam', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 4', 'Active', 'AI_1,AI_2', 'Mobile Cam', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 5', 'Active', 'AI_3', 'Mobile Cam', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 6', 'Active', 'AI_1,AI_2', 'Mobile Cam', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 7', 'Active', 'AI_1,AI_2', 'Mobile Cam', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 8', 'Active', 'AI_1,AI_2', 'Mobile Cam', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 9', 'Active', 'AI_1,AI_2', 'Edge Devices', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 10', 'Active', 'AI_1,AI_2', 'Edge Devices', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 11', 'Active', 'AI_1,AI_3', 'Edge Devices', 'Secmotic 18', '26/3/24 18:30'],
  ['123123123', 'Cam 12', 'Active', 'AI_1,AI_2', 'Edge Devices', 'Secmotic 18', '26/3/24 18:30'],
];
const BODY3 = [
  ['123123123', 'Alert 1', 'Attended', 'Secmotic 18', '123123123', '123123123', '26/3/24 18:30'],
  ['123123123', 'Alert 2', 'Attended', 'Secmotic 18', '123123123', '123123123', '26/3/24 18:30'],
  ['123123123', 'Alert 3', 'Attended', 'Secmotic 18', '123123123', '123123123', '26/3/24 18:30'],
  ['123123123', 'Alert 4', 'Unattended', 'Secmotic 18', '123123123', '123123123', '26/3/24 18:30'],
];

export {
  HEADER1, HEADER2, HEADER3, BODY1, BODY2, BODY3,
};
