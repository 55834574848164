import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  MapContainer, TileLayer, Marker, Popup,
} from 'react-leaflet';
import CVSecFilter from '../../components/CVSecFilter';
import CVSecSearch from '../../components/CVSecSearch';
import CVSecTable from '../../components/CVSecTable';
import CVSecMenuLeft from '../../components/CVSecMenuLeft';
import iconUser from '../../resources/pages/dashboards/iconUser.svg';
import video from '../../resources/pages/dashboards/video.svg';
import notificacionBell from '../../resources/pages/dashboards/notificacionBell.svg';
import 'leaflet/dist/leaflet.css';
import {
  HEADER1, HEADER2, HEADER3, BODY1, BODY2, BODY3,
} from './data/dataTable';
import styles from './styles.module.scss';

const {
  containerDashboards, containerFilters, containerSearch, containerMap,
  filters, mapLayout, containerTable,
} = styles;

function Dashboard({ intl }) {
  // PROVISIONAL HASTA IMPLEMENTACIÓN CON DATOS REALES
  const [showMap, setShowMap] = useState(true);

  return (
    <>
      <CVSecMenuLeft setShowMap={() => setShowMap((prev) => !prev)} />
      <div className={containerDashboards}>
        <div className={containerFilters}>
          <div className={filters}>
            <CVSecFilter
              id="users"
              page="dashboards"
              imgFilter={iconUser}
              title={intl.formatMessage({ id: 'accessibility.dashboards.filterUser' })}
              options={['all', 'active', 'inactive']}
            />
            <CVSecFilter
              id="video"
              page="dashboards"
              imgFilter={video}
              title={intl.formatMessage({ id: 'accessibility.dashboards.video' })}
              options={['all', 'mobileCams', 'edgeDevices', 'smartphoneCams']}
            />
            <CVSecFilter
              id="notifications"
              page="dashboards"
              imgFilter={notificacionBell}
              title={intl.formatMessage({ id: 'accessibility.dashboards.notificacions' })}
              options={['all', 'attendedNotifications', 'unattendedNotifications', 'unattendedNotificationsInProgress']}
            />
          </div>
        </div>
        <div className={containerSearch}>
          <CVSecSearch
            placeholder={intl.formatMessage({ id: 'dashboards.cvsecSearch.placeholder' })}
          />
        </div>
        {showMap
          ? (
            <div className={containerMap}>
              <div className={mapLayout}>
                <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[51.505, -0.09]}>
                    <Popup>
                      A pretty CSS3 popup.
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            </div>
          )
          : (
            <div className={containerTable}>
              <div className="mt-5">
                <CVSecTable
                  title={intl.formatMessage({ id: 'table.users.title' })}
                  headers={HEADER1}
                  body={BODY1}
                  name="users"
                />
              </div>
              <div className="mt-5">
                <CVSecTable
                  title={intl.formatMessage({ id: 'table.videoStreams.title' })}
                  headers={HEADER2}
                  body={BODY2}
                  name="videoStreams"
                />
              </div>
              <div className="mt-5">
                <CVSecTable
                  title={intl.formatMessage({ id: 'table.notifications.title' })}
                  headers={HEADER3}
                  body={BODY3}
                  name="notifications"
                />
              </div>
            </div>
          )}
      </div>
    </>
  );
}

export default injectIntl(Dashboard);
