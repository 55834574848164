import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import CVSecHeader from '../CVSecHeader';
import CVSecNavbar from '../CVSecNavbar';
import styles from './styles.module.scss';

const { containerTemplate, containerPageDashboard, containerRestPage } = styles;

function CVSecTemplate({ children }) {
  const [isInDashboard, setIsIndashboard] = useState(true);

  useEffect(() => {
    setIsIndashboard(window.location.pathname.includes('/dashboards'));
  }, []);

  return (
    <div className={containerTemplate}>
      <CVSecHeader />
      {!isInDashboard && <CVSecNavbar />}
      <div className={`${isInDashboard ? containerPageDashboard : containerRestPage}`}>
        {children}
      </div>
    </div>
  );
}

export default injectIntl(CVSecTemplate);
