const NOTIFICATION_HEADER = {
  id: { sortable: true },
  name: { sortable: true },
  streamName: { sortable: true },
  status: { sortable: true },
  type: { sortable: true },
  location: { sortable: true },
  lastUpdateBy: { sortable: true },
  assignedTo: { sortable: true },
  createdAt: { sortable: true },
  settings: {},
};

export default NOTIFICATION_HEADER;
