const DEVICE_HEADER = {
  id: { sortable: true },
  name: { sortable: true },
  type: { sortable: true },
  location: { sortable: true },
  resolution: { sortable: true },
  apikey: { sortable: false },
  settings: {},
};

export default DEVICE_HEADER;
