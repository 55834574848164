// Colors
const primary = '#37E0CC';
const secondary = '#1F5B58';
const tertiary = '#1F2221';
const cuaternary = '#34978B';
const blackgroundBody = '#f3f3f3';
const blackgroundSwitch = '#D9D9D9';
const backgroundTableHeader = '#456B72';
const backgroundPolygon = '#00ffe124';
const black = '#000000';
const white = '#ffffff';
const grey1 = '#828282';
const grey2 = '#E0E0E0';
const warning = '#ff0000';
const shadow = '#2e2e2ed2';
const shadow2 = '#616161';
const shadow3 = '#a0a0a0';

// Grid
// These values represent the lower limit for each breakpoint
const xs = '0px';
const sm = '576px';
const md = '768px';
const lg = '992px';
const xl = '1200px';

export {
  primary,
  secondary,
  tertiary,
  cuaternary,
  blackgroundBody,
  blackgroundSwitch,
  backgroundTableHeader,
  backgroundPolygon,
  black,
  white,
  grey1,
  grey2,
  warning,
  shadow,
  shadow2,
  shadow3,
  xs,
  sm,
  md,
  lg,
  xl,
};
