const VIDEOSTREAM_HEADER = {
  id: { sortable: true },
  name: { sortable: true },
  status: { sortable: true },
  aiModels: { sortable: true },
  url: { sortable: true },
  deviceName: { sortable: true },
  settings: {},
};

export default VIDEOSTREAM_HEADER;
